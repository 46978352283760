.mapboxgl-popup-content {
    color: rgb(42, 42, 42);
}

.mapboxgl-popup {
    z-index: 90001;
}

.MuiIconButton-root {
    font-size: 1rem!important;
}

.MuiTypography-body1 {
    font-size: 0.9rem!important;
}
