.MuiInputLabel-shrink {
    transform: translate(0, 6.5px) scale(0.75)!important;
}

.query {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1560px) {
    .query {
        flex-direction: column;
    }

    .legend {
        margin: 10px 20px 10px 30px;
    }
}
